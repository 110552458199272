import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          environment
        }
      }
    }
  `);

  return (
    <main>
      <title>Home Page</title>
      <h1>POC</h1>
      <p>This is a test GatsbyJS website.</p>
      <p>
        <em>Environment: </em>
        {data.site.siteMetadata.environment}
      </p>
    </main>
  );
};

export default IndexPage;
